.x-maincompose {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    font-size: 1.25rem;
    font-weight: bold;
    margin-right: 10px;
    margin-left: 2.5%;
}

.x {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    font-size: 1.25rem;
    font-weight: bold;
    position: absolute;
    right: 20px;
    top: 20px;
}

.modalc-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.modalc-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.3);
}

.modalc-box {
    position: relative;
    margin-left: "15%";
    margin-top: "9%";
    width: "48%";
    height: "74%";
    overflow-y: auto;
    z-index: 101;
}

.modalc-body {
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Abel;
}

.trash {
    height: 40px;
    width: 160px;
    background-color: #274D5A;
    border: 2px solid #274D5A;
    color: white;
    background-repeat: no-repeat;
    outline: none;
    cursor: pointer;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    font-family: Abel;
    justify-content: right;
    align-items: right;
}

.trash:hover {
    background-color: #407c91;
    border: 2px solid #407c91;
    color: white;
  }

.savebutton {
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid;
    color: #000000;
    padding: 10px 24px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 2.5%;
    width: 150px;
    font-family: Abel;
    font-size: 16px;
}

.savebutton:hover {
    background-color: #c7c7c7;
    border: 1px solid #c7c7c7;
    color: black;
    border-radius: 4px;
    padding: 10px 24px;
  }

.sendbuttons {
    border-radius: 4px;
    background-color: #274D5A;
    border: 1px solid #274D5A;
    color: white;
    padding: 10px 24px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 2.5%;
    width: 150px;
    font-family: Abel;
    font-size: 16px;
}

.sendbuttons:hover {
    background-color: #407c91;
    border: 1px solid #407c91;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
}

.recover {
    height: 40px;
    width: 160px;
    background-color: #274D5A;
    border: 2px solid #274D5A;
    color: white;
    background-repeat: no-repeat;
    outline: none;
    cursor: pointer;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    font-family: Abel;
    justify-content: right;
    align-items: right;
}

.recover:hover {
    background-color: #407c91;
    border: 2px solid #407c91;
    color: white;
  }

.continue-button {
    background-color: #274D5A;
    border: 1px solid #274D5A;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
}

.continue-button:hover {
    background-color: #407c91;
    border: 1px solid #407c91;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
}

.navyButton {
    background-color: #274D5A;
    border: 2px solid #274D5A;
    color: white;
    margin-right: 20px;
    margin-top: 5px;
}

.navyButton:hover {
    background-color: #407c91;
    border: 2px solid #407c91;
    color: white;
    margin-right: 20px;
    margin-top: 5px;
}

.exit-button {
    background-color: #274D5A;
    border: 1px solid #274D5A;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
}

.exit-button:hover {
    background-color: #407c91;
    border: 1px solid #407c91;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
}

.request-button {
    background-color: #274D5A;
    border: 1px solid #274D5A;
    color: white;
    border-radius: 4px;
    padding: 4px 12px;
}

.request-button:hover {
    background-color: #407c91;
    border: 1px solid #407c91;
    color: white;
    border-radius: 4px;
    padding: 4px 12px;
}

.cancel-button {
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #000000;
    color: #000000;
    padding: 10px 24px;
}
.cancel-button:hover {
    background-color: #c7c7c7;
    border: 1px solid #000000;
    color: black;
    border-radius: 4px;
    padding: 10px 24px;
}