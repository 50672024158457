@import url('https://fonts.googleapis.com/css?family=Abel');
 
* {
    box-sizing: border-box;
  }

.introbody {
    font-family: Abel;
    min-height: 100vh;
    overflow: hidden;
    background: linear-gradient(180deg, #97D4D7 0%, #AEDDDE 19.27%, #F0F9FA 60.94%, #FFFFE0 82.29%, #FCD3AE 100%);
}
 
.resourcesbody {
    font-family: Abel;
    min-height: 100vh;
    overflow-y: scroll;
    height: 300px;
    background: linear-gradient(180deg, #97D4D7 0%, #AEDDDE 19.27%, #F0F9FA 60.94%, #FFFFE0 82.29%, #FCD3AE 100%);
}
  
.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: auto;
}   

.title {
    margin-top: 0.5%;
    width: 100%;
    font-size: 32px;
    text-align: center;
    margin-bottom: 0.5%;
}
 
.fieldLabel {
    margin-left: 20%;
    font-size: 16px;
    font-weight: bold;
}
 
.field {
    margin-left: 20%;
    margin-right: auto;  
    margin-bottom: 15px;
    width: 60%;
    height: 28px;
    border-style: none;
    border-radius: 4px;
    background-color: #ffffff;
    opacity: 0.6;
    position: relative;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    padding: 10px;
    font-size: 18px;
    font-family: Abel;
}
 
.field:hover {
    box-shadow: 7px 7px 5px grey;
}
  
.field:focus {
    opacity: 1;
    outline: none;
}

.errorField {
    margin-left: 20%;
    margin-right: auto;  
    width: 60%;
    height: 30px;
    border-radius: 4px;
    background-color: #ffffff;
    opacity: 0.6;
    position: relative;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    padding: 10px;
    font-size: 18px;
    font-family: Abel;
    border: solid 3px salmon;
}

.errorField:hover {
    box-shadow: 7px 7px 5px grey;
}
  
.errorField:focus {
    opacity: 1;
    outline: none;
}

.sFieldLabel {
    margin-left: 20%;
    font-size: 16px;
    font-weight: bold;
}

.sField {
    margin-left: 20%;
    margin-right: auto;  
    margin-bottom: 15px;
    width: 45%;
    height: 28px;
    border-style: none;
    border-radius: 4px;
    background-color: #ffffff;
    opacity: 0.6;
    position: relative;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    padding: 10px;
    font-size: 18px;
    font-family: Abel;
}
 
.sField:hover {
    box-shadow: 7px 7px 5px grey;
}
  
.sField:focus {
    opacity: 1;
    outline: none;
}

.errorSField {
    margin-left: 20%;
    margin-right: auto;  
    width: 45%;
    height: 30px;
    border-radius: 4px;
    background-color: #ffffff;
    opacity: 0.6;
    position: relative;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    padding: 10px;
    font-size: 18px;
    font-family: Abel;
    border: solid 3px salmon;
}

.errorSField:hover {
    box-shadow: 7px 7px 5px grey;
}
  
.errorSField:focus {
    opacity: 1;
    outline: none;
}

.errors {
    text-align: left;
    font-size: 18px;
    margin-left: 20.5%;
    margin-bottom: 15px;
    color: #CD5B45;
}
 
.checkbox {
    opacity: 0.6;
    margin-left: 20%;
    cursor: pointer;
}

.button {
    width: 75px;
    height: 90px;
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    border: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #FCD3AE;
    outline: none;
    margin-bottom: 10px;
    font-size: 28px;
    text-align: center;
    font-family: Abel;
}
 
.button:hover {
    box-shadow: 5px 5px 5px grey;
    cursor: pointer;
}
 
.button:active {
    transform: translateY(2px);
    cursor: grabbing;
}
 
.center {
    text-align: center;
    margin-bottom: "20px";
}
 
.centerpadding {
    text-align: center;
    padding-left: 100px;
    padding-right: 100px;
}

.close {
    color: black;
    font-size: 20px;
    float: right;
    cursor: pointer;
}
 
.close:hover {
    font-size: 25px;
}
 
.close:active {
    transform: translateY(2px);
}
 
.modal {
    position: fixed;
    z-index: 1;
    width:100%;
    height: 100%;
}
 
.modalContent {
    background-color: white;
    opacity: 0.99;
    position: absolute;
    top: -25px;
    left: 25%;
    width: 50%;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid black;
}
 
.bold {
    font-weight: bold;
}
 
.popup {
    text-decoration: underline;
}
 
.popup:hover {
    font-weight: bold;
    cursor: pointer;
}

.stylefooter {
    background-color: #274D5A;
    position: fixed;
    font-size: 14px;
    font-family: Abel;
    color: white;
    bottom: 0;
    width: 100%;
    margin-top: 75px;
    text-align: center;
    padding-top: 10px;
    height: 2.5rem;
  }