body {
  font-family: "RobotoRegular", Helvetica, Arial,sans-serif; 
  font-size: 14px;
  margin: 0;
  max-height: 100vh;
  overflow: hidden;
}

.navbar {
  background-color: white;
  list-style: none;
}
.component-responsive-image img {
  padding: 1em;
  width: 100%;
}

.companyList {
  padding: 1em;
  background-color: #efefef;
}

/* Default Breakpoints: xs: 0-767, sm: 768-991, md: 992-1199, lg: 1200-1599, xl 1600+ */

@media screen and (min-width: 0px) {
  .component-responsive-image img {
    padding: 0;
    width: 100%;
  }
  .companyList h2, .kendo-info h2 {
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .navbar {
    height: 100vh;
    padding-top: 1em;
    background-color: #efefef;
  }
  .component-responsive-image {
    height: 100%;
  }
  .component-responsive-image img {
    padding: 1em;
    max-width: auto;
    height: 100%;
  }
  .companyList {
    background-color: #fff;
  }
  .kendo-info {
    font-size: 1.25em;
  }
}

@media screen and (min-width: 992px) {
  .kendo-info {
    font-size: 1.5em;
  }
}

.logo {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 1000;
}

.row {
  display: flex;
  flex-direction: row;
}

.bot_color {
  background-color:#274D5A ;
  font-family: Abel;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: white;
  width: 100%;
  margin-top: 75px;
  text-align: center;
  padding-top: 10px;
  height: 2.5rem;
}

.card-header {
  max-width: 60%;
  max-height: 80%;
}

.button {
  width: 105px;
  height: 128px;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  border: none;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: #FCD3AE;
  outline: none;
  margin-bottom: 35px;
  font-size: 20px;
  text-align: center;
  font-family: Abel;
}

/* CSS for Add Friend Modal */

.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0,0,0,0.3);
}

.modal-box {
  position: relative;
  top: 50%;
  left: 45%;
  transform: translate(-50%,-50%);
  min-height: 20%;
  width: 30%;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.25);
  z-index: 101;
  padding: 20px;
}

.modal-header {
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  font-family: Abel;
}

.modal-body {
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Abel;
}
