@import url('https://fonts.googleapis.com/css?family=Abel');

.body {
  background: linear-gradient(180deg, #97D4D7 0%, #AEDDDE 19.27%, #F0F9FA 60.94%, #FFFFE0 82.29%, #FCD3AE 100%);
}

.loading {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 100px;
    height: 100px;
    margin: -75px 0 0 -75px;
    border: 12px solid #F0F9FA;
    border-radius: 50%;
    border-top: 16px solid  #407c91;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 1.2s linear infinite;
  }

  .loadingLogo {
    margin-top: 11%;
    display: block;
    margin-left: 40%;
    width: 350px;
    height: auto;
    top: 200px;
  }   

  .text {
    margin-top: 9%;
    font-family: Abel;
    text-align: center;
    font-size: 50px;
    color: #407c91;
    margin-bottom: 50%;
  }

  .bot_color {
    background-color:#274D5A ;
    font-family: Abel;
    font-style: normal;
    font-weight: normal;
    background-color: #274D5A;
    font-size: 14px;
    color: white;
    width: 100%;
    margin-top: 75px;
    text-align: center;
    padding-top: 10px;
    height: 2.5rem;
    display: "flex";
    flex-direction: "row";
    justify-content: "center";
    padding-top: 11;
    padding-bottom: 11;
    padding-left: 11;
    position: 'absolute';
    bottom: 0
  }

  .stylefooter {
    background-color: #274D5A;
    position: fixed;
    font-size: 14px;
    font-family: Abel;
    color: white;
    bottom: 0;
    width: 100%;
    margin-top: 75px;
    text-align: center;
    padding-top: 10px;
    height: 2.5rem;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
