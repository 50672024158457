@import url(https://fonts.googleapis.com/css?family=Abel);
@import url(https://fonts.googleapis.com/css?family=Abel);
@import url(https://fonts.googleapis.com/css?family=Abel);
@import url(https://fonts.googleapis.com/css?family=Abel);
@import url(https://fonts.googleapis.com/css?family=Abel);
body, html {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.welcomebody {
    width: 100%;
    height: 2880px;
    background: linear-gradient(180deg, #97D4D7 0%, #AEDDDE 19.27%, #F0F9FA 60.94%, #FFFFE0 82.29%, #FCD3AE 100%);
    font-family: Abel;
}

.header {
    padding: 42px 100%;
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    display: flex;
    line-height: 20px;
    z-index: 1000000
  }

.footer {
    background-color: #274D5A;
    position: fixed;
    font-size: 14px;
    font-family: Abel;
    color: white;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 45px;
    z-index: 1000000;
  }

/* Split the screen in half */
.split {
    height: 675px;
    width: 50%;
    z-index: 1;
    position: absolute;
    overflow-x: hidden;
    padding-top: 300px;
  }
  
  /* Control the left side */
  .left {
    left: 0;
  }
  
  /* Control the right side */
  .right {
    right: 0;
  }

  /* If you want the content centered horizontally and vertically */
  .centered {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    justify-content: center;
    align-items: center;
  }

.boxbutton {
    background-color: #274D5A;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  .button3 {
    outline: none;
    background-color: #ffffff; 
    color: #274D5A; 
    border: 2px solid #274D5A;
    border-radius: 4px;
    padding: 5px 18px;
    font-family: Abel;
    width: 150px;
    font-size: 16px;
    position: fixed;
    right: 380px;
    top: 24px;
  }
  
  .button3:hover {
    background-color: #407587;
    border: 2px solid #407587;
    color: white;
    cursor: pointer;
  }

  .button3:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .button1 {
    outline: none;
    background-color: #274D5A; 
    color: white; 
    border: 2px solid #274D5A;
    border-radius: 4px;
    padding: 5px 18px;
    font-family: Abel;
    width: 150px;
    font-size: 16px;
    position: fixed;
    right: 200px;
    top: 24px;
  }
  
  .button1:hover {
    background-color: #407c91;
    border: 2px solid #407c91;
    color: white;
    cursor: pointer;
  }

  .button1:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .button2 {
    outline: none;
    background-color: #274D5A; 
    color: white; 
    border: 2px solid #274D5A;
    border-radius: 4px;
    padding: 5px 18px;
    font-family: Abel;
    width: 150px;
    font-size: 16px;
    position: fixed;
    right: 20px;
    top: 24px;
  }
  
  .button2:hover {
    background-color: #407587;
    border: 2px solid #407587;
    color: white;
    cursor: pointer;
  }

  .button2:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

#block1 {
  height: 720px;
}

#block2 {
  height: 720px;
}

#block3 {
  height: 720px;
}

#block4 {
  height: 720px;
}

.bigbutton {
  background-color: #ffffff; 
  color: #274D5A; 
  border: 2px solid #ffffff;
  outline: none;
  border-radius: 4px;
  padding: 20px 40px;
  font-family: Abel;
  width: 200px;
  font-size: 30px;
  box-shadow: 0 12px #407587;
}

.bigbutton:hover {
  background-color: #d4d4d4;
  border: 2px solid #d4d4d4;
  color: #274D5A;
  cursor: pointer;
  
}

.bigbutton:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
  box-shadow: 0 8px #274D5A;
  transform: translateY(20px);
}

* {
    box-sizing: border-box;
  }

.introbody {
    font-family: Abel;
    min-height: 100vh;
    overflow: hidden;
    background: linear-gradient(180deg, #97D4D7 0%, #AEDDDE 19.27%, #F0F9FA 60.94%, #FFFFE0 82.29%, #FCD3AE 100%);
}
 
.resourcesbody {
    font-family: Abel;
    min-height: 100vh;
    overflow-y: scroll;
    height: 300px;
    background: linear-gradient(180deg, #97D4D7 0%, #AEDDDE 19.27%, #F0F9FA 60.94%, #FFFFE0 82.29%, #FCD3AE 100%);
}
  
.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: auto;
}   

.title {
    margin-top: 0.5%;
    width: 100%;
    font-size: 32px;
    text-align: center;
    margin-bottom: 0.5%;
}
 
.fieldLabel {
    margin-left: 20%;
    font-size: 16px;
    font-weight: bold;
}
 
.field {
    margin-left: 20%;
    margin-right: auto;  
    margin-bottom: 15px;
    width: 60%;
    height: 28px;
    border-style: none;
    border-radius: 4px;
    background-color: #ffffff;
    opacity: 0.6;
    position: relative;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    padding: 10px;
    font-size: 18px;
    font-family: Abel;
}
 
.field:hover {
    box-shadow: 7px 7px 5px grey;
}
  
.field:focus {
    opacity: 1;
    outline: none;
}

.errorField {
    margin-left: 20%;
    margin-right: auto;  
    width: 60%;
    height: 30px;
    border-radius: 4px;
    background-color: #ffffff;
    opacity: 0.6;
    position: relative;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    padding: 10px;
    font-size: 18px;
    font-family: Abel;
    border: solid 3px salmon;
}

.errorField:hover {
    box-shadow: 7px 7px 5px grey;
}
  
.errorField:focus {
    opacity: 1;
    outline: none;
}

.sFieldLabel {
    margin-left: 20%;
    font-size: 16px;
    font-weight: bold;
}

.sField {
    margin-left: 20%;
    margin-right: auto;  
    margin-bottom: 15px;
    width: 45%;
    height: 28px;
    border-style: none;
    border-radius: 4px;
    background-color: #ffffff;
    opacity: 0.6;
    position: relative;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    padding: 10px;
    font-size: 18px;
    font-family: Abel;
}
 
.sField:hover {
    box-shadow: 7px 7px 5px grey;
}
  
.sField:focus {
    opacity: 1;
    outline: none;
}

.errorSField {
    margin-left: 20%;
    margin-right: auto;  
    width: 45%;
    height: 30px;
    border-radius: 4px;
    background-color: #ffffff;
    opacity: 0.6;
    position: relative;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    padding: 10px;
    font-size: 18px;
    font-family: Abel;
    border: solid 3px salmon;
}

.errorSField:hover {
    box-shadow: 7px 7px 5px grey;
}
  
.errorSField:focus {
    opacity: 1;
    outline: none;
}

.errors {
    text-align: left;
    font-size: 18px;
    margin-left: 20.5%;
    margin-bottom: 15px;
    color: #CD5B45;
}
 
.checkbox {
    opacity: 0.6;
    margin-left: 20%;
    cursor: pointer;
}

.button {
    width: 75px;
    height: 90px;
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    border: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #FCD3AE;
    outline: none;
    margin-bottom: 10px;
    font-size: 28px;
    text-align: center;
    font-family: Abel;
}
 
.button:hover {
    box-shadow: 5px 5px 5px grey;
    cursor: pointer;
}
 
.button:active {
    transform: translateY(2px);
    cursor: -webkit-grabbing;
    cursor: grabbing;
}
 
.center {
    text-align: center;
    margin-bottom: "20px";
}
 
.centerpadding {
    text-align: center;
    padding-left: 100px;
    padding-right: 100px;
}

.close {
    color: black;
    font-size: 20px;
    float: right;
    cursor: pointer;
}
 
.close:hover {
    font-size: 25px;
}
 
.close:active {
    transform: translateY(2px);
}
 
.modal {
    position: fixed;
    z-index: 1;
    width:100%;
    height: 100%;
}
 
.modalContent {
    background-color: white;
    opacity: 0.99;
    position: absolute;
    top: -25px;
    left: 25%;
    width: 50%;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid black;
}
 
.bold {
    font-weight: bold;
}
 
.popup {
    text-decoration: underline;
}
 
.popup:hover {
    font-weight: bold;
    cursor: pointer;
}

.stylefooter {
    background-color: #274D5A;
    position: fixed;
    font-size: 14px;
    font-family: Abel;
    color: white;
    bottom: 0;
    width: 100%;
    margin-top: 75px;
    text-align: center;
    padding-top: 10px;
    height: 2.5rem;
  }
.body {
  background: linear-gradient(180deg, #97D4D7 0%, #AEDDDE 19.27%, #F0F9FA 60.94%, #FFFFE0 82.29%, #FCD3AE 100%);
}

.loading {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 100px;
    height: 100px;
    margin: -75px 0 0 -75px;
    border: 12px solid #F0F9FA;
    border-radius: 50%;
    border-top: 16px solid  #407c91;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 1.2s linear infinite;
  }

  .loadingLogo {
    margin-top: 11%;
    display: block;
    margin-left: 40%;
    width: 350px;
    height: auto;
    top: 200px;
  }   

  .text {
    margin-top: 9%;
    font-family: Abel;
    text-align: center;
    font-size: 50px;
    color: #407c91;
    margin-bottom: 50%;
  }

  .bot_color {
    background-color:#274D5A ;
    font-family: Abel;
    font-style: normal;
    font-weight: normal;
    background-color: #274D5A;
    font-size: 14px;
    color: white;
    width: 100%;
    margin-top: 75px;
    text-align: center;
    padding-top: 10px;
    height: 2.5rem;
    display: "flex";
    flex-direction: "row";
    justify-content: "center";
    padding-top: 11;
    padding-bottom: 11;
    padding-left: 11;
    position: 'absolute';
    bottom: 0
  }

  .stylefooter {
    background-color: #274D5A;
    position: fixed;
    font-size: 14px;
    font-family: Abel;
    color: white;
    bottom: 0;
    width: 100%;
    margin-top: 75px;
    text-align: center;
    padding-top: 10px;
    height: 2.5rem;
  }

  @-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.body {
    position: absolute;
    margin: 0px;
    overflow: hidden;
    top: 0px;
    bottom: 0px;
    width: 100%;  
}

.halfback {
    background:linear-gradient(180deg, #274D5A 35%, #FFFFFF 35%);
    min-height: 100vh;
    font-family: Abel;
}

.nextbutton {
    width: 75px;
    height: 90px;
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    border: none;
    display: block;
    background-color: #FCD3AE;
    outline: none;
    margin-bottom: 10px;
    font-size: 28px;
    font-family: Abel;
    left: 84.5%;
    top: 80%;
    position: absolute;
}

.nextbutton:hover {
    box-shadow: 5px 5px 5px grey;
    cursor: pointer;
}
 
.nextbutton:active {
    transform: translateY(2px);
    cursor: -webkit-grabbing;
    cursor: grabbing;
}
.halfback {
    background:linear-gradient(180deg, #274D5A 35%, #FFFFFF 35%);
    min-height: 100vh;
    font-family: Abel;
}

.nextbutton {
    width: 75px;
    height: 90px;
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    border: none;
    display: block;
    background-color: #FCD3AE;
    outline: none;
    margin-bottom: 10px;
    font-size: 28px;
    font-family: Abel;
    left: 84.5%;
    top: 80%;
    position: absolute;
}

.nextbutton:hover {
    box-shadow: 5px 5px 5px grey;
    cursor: pointer;
}
 
.nextbutton:active {
    transform: translateY(2px);
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.backbutton {
    width: 75px;
    height: 90px;
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    border: none;
    display: block;
    background-color: #FCD3AE;
    outline: none;
    margin-bottom: 10px;
    font-size: 28px;
    font-family: Abel;
    left: 8%;
    top: 80%;
    position: absolute;
}

.backbutton:hover {
    box-shadow: 5px 5px 5px grey;
    cursor: pointer;
}
 
.backbutton:active {
    transform: translateY(2px);
    cursor: -webkit-grabbing;
    cursor: grabbing;
}
.x-maincompose {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    font-size: 1.25rem;
    font-weight: bold;
    margin-right: 10px;
    margin-left: 2.5%;
}

.x {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    font-size: 1.25rem;
    font-weight: bold;
    position: absolute;
    right: 20px;
    top: 20px;
}

.modal-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.3);
}

.modal-box {
    position: relative;
    top: 50%;
    left: 45%;
    transform: translate(-50%,-50%);
    min-height: 30%;
    width: 40%;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
    z-index: 101;
    padding: 20px;
}

.modal-top {
    padding: 10px 10px;
    display: flex;
    justify-content: right;
    text-align: right;
    align-items: right;
    font-size: 16px;
    font-weight: bold;
    font-family: Abel;
}

.modal-header {
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    font-family: Abel;
}

.modal-body {
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Abel;
}

.trash {
    height: 40px;
    width: 160px;
    background-color: #274D5A;
    border: 2px solid #274D5A;
    color: white;
    background-repeat: no-repeat;
    outline: none;
    cursor: pointer;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    font-family: Abel;
    justify-content: right;
    align-items: right;
}

.trash:hover {
    background-color: #407c91;
    border: 2px solid #407c91;
    color: white;
    cursor: pointer;
  }

.trash:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.savebutton {
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #ffffff;
    color: #000000;
    padding: 10px 24px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 2.5%;
    width: 150px;
    font-family: Abel;
    font-size: 16px;
}

.savebutton:hover {
    background-color: #c7c7c7;
    border: 1px solid #c7c7c7;
    color: black;
    border-radius: 4px;
    padding: 10px 24px;
    cursor: pointer;
  }

.savebutton:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.sendbuttons {
    border-radius: 4px;
    background-color: #274D5A;
    border: 1px solid #274D5A;
    color: white;
    padding: 10px 24px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 2.5%;
    width: 150px;
    font-family: Abel;
    font-size: 16px;
}

.sendbuttons:hover {
    background-color: #407c91;
    border: 1px solid #407c91;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
    cursor: pointer;
}

.sendbuttons:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.recover {
    height: 40px;
    width: 160px;
    background-color: #274D5A;
    border: 2px solid #274D5A;
    color: white;
    background-repeat: no-repeat;
    outline: none;
    cursor: pointer;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    font-family: Abel;
    justify-content: right;
    align-items: right;
}

.recover:hover {
    background-color: #407c91;
    border: 2px solid #407c91;
    color: white;
    cursor: pointer;
  }

.recover:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.continue-button {
    background-color: #274D5A;
    border: 1px solid #274D5A;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
}

.continue-button:hover {
    background-color: #407c91;
    border: 1px solid #407c91;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
    cursor: pointer;
}

.continue-button:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.navyButton {
    background-color: #274D5A;
    border: 2px solid #1c1d1d;
    color: white;
    margin-right: 20px;
    margin-top: 5px;
}

.navyButton:hover {
    background-color: #407c91;
    border: 2px solid #407c91;
    color: white;
    margin-right: 20px;
    margin-top: 5px;
    cursor: pointer;
}

.navyButton:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.exit-button {
    background-color: #274D5A;
    border: 1px solid #274D5A;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
}

.exit-button:hover {
    background-color: #407c91;
    border: 1px solid #407c91;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
    cursor: pointer;
}

.exit-button:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.request-button {
    background-color: #274D5A;
    border: 1px solid #274D5A;
    color: white;
    border-radius: 4px;
    padding: 4px 12px;
}

.request-button:hover {
    background-color: #407c91;
    border: 1px solid #407c91;
    color: white;
    border-radius: 4px;
    padding: 4px 12px;
    cursor: pointer;
}

.request-button:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.cancel-button {
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #000000;
    color: #000000;
    padding: 10px 24px;
}
.cancel-button:hover {
    background-color: #c7c7c7;
    border: 1px solid #000000;
    color: black;
    border-radius: 4px;
    padding: 10px 24px;
    cursor: pointer;
}

.cancel-button:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.x-maincompose {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    font-size: 1.25rem;
    font-weight: bold;
    margin-right: 10px;
    margin-left: 2.5%;
}

.x {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    font-size: 1.25rem;
    font-weight: bold;
    position: absolute;
    right: 20px;
    top: 20px;
}

.modalc-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.modalc-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.3);
}

.modalc-box {
    position: relative;
    margin-left: "15%";
    margin-top: "9%";
    width: "48%";
    height: "74%";
    overflow-y: auto;
    z-index: 101;
}

.modalc-body {
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Abel;
}

.trash {
    height: 40px;
    width: 160px;
    background-color: #274D5A;
    border: 2px solid #274D5A;
    color: white;
    background-repeat: no-repeat;
    outline: none;
    cursor: pointer;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    font-family: Abel;
    justify-content: right;
    align-items: right;
}

.trash:hover {
    background-color: #407c91;
    border: 2px solid #407c91;
    color: white;
  }

.savebutton {
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid;
    color: #000000;
    padding: 10px 24px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 2.5%;
    width: 150px;
    font-family: Abel;
    font-size: 16px;
}

.savebutton:hover {
    background-color: #c7c7c7;
    border: 1px solid #c7c7c7;
    color: black;
    border-radius: 4px;
    padding: 10px 24px;
  }

.sendbuttons {
    border-radius: 4px;
    background-color: #274D5A;
    border: 1px solid #274D5A;
    color: white;
    padding: 10px 24px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 2.5%;
    width: 150px;
    font-family: Abel;
    font-size: 16px;
}

.sendbuttons:hover {
    background-color: #407c91;
    border: 1px solid #407c91;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
}

.recover {
    height: 40px;
    width: 160px;
    background-color: #274D5A;
    border: 2px solid #274D5A;
    color: white;
    background-repeat: no-repeat;
    outline: none;
    cursor: pointer;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    font-family: Abel;
    justify-content: right;
    align-items: right;
}

.recover:hover {
    background-color: #407c91;
    border: 2px solid #407c91;
    color: white;
  }

.continue-button {
    background-color: #274D5A;
    border: 1px solid #274D5A;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
}

.continue-button:hover {
    background-color: #407c91;
    border: 1px solid #407c91;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
}

.navyButton {
    background-color: #274D5A;
    border: 2px solid #274D5A;
    color: white;
    margin-right: 20px;
    margin-top: 5px;
}

.navyButton:hover {
    background-color: #407c91;
    border: 2px solid #407c91;
    color: white;
    margin-right: 20px;
    margin-top: 5px;
}

.exit-button {
    background-color: #274D5A;
    border: 1px solid #274D5A;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
}

.exit-button:hover {
    background-color: #407c91;
    border: 1px solid #407c91;
    color: white;
    border-radius: 4px;
    padding: 10px 24px;
}

.request-button {
    background-color: #274D5A;
    border: 1px solid #274D5A;
    color: white;
    border-radius: 4px;
    padding: 4px 12px;
}

.request-button:hover {
    background-color: #407c91;
    border: 1px solid #407c91;
    color: white;
    border-radius: 4px;
    padding: 4px 12px;
}

.cancel-button {
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #000000;
    color: #000000;
    padding: 10px 24px;
}
.cancel-button:hover {
    background-color: #c7c7c7;
    border: 1px solid #000000;
    color: black;
    border-radius: 4px;
    padding: 10px 24px;
}
body {
  font-family: "RobotoRegular", Helvetica, Arial,sans-serif; 
  font-size: 14px;
  margin: 0;
  max-height: 100vh;
  overflow: hidden;
}

.navbar {
  background-color: white;
  list-style: none;
}
.component-responsive-image img {
  padding: 1em;
  width: 100%;
}

.companyList {
  padding: 1em;
  background-color: #efefef;
}

/* Default Breakpoints: xs: 0-767, sm: 768-991, md: 992-1199, lg: 1200-1599, xl 1600+ */

@media screen and (min-width: 0px) {
  .component-responsive-image img {
    padding: 0;
    width: 100%;
  }
  .companyList h2, .kendo-info h2 {
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .navbar {
    height: 100vh;
    padding-top: 1em;
    background-color: #efefef;
  }
  .component-responsive-image {
    height: 100%;
  }
  .component-responsive-image img {
    padding: 1em;
    max-width: auto;
    height: 100%;
  }
  .companyList {
    background-color: #fff;
  }
  .kendo-info {
    font-size: 1.25em;
  }
}

@media screen and (min-width: 992px) {
  .kendo-info {
    font-size: 1.5em;
  }
}

.logo {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 1000;
}

.row {
  display: flex;
  flex-direction: row;
}

.bot_color {
  background-color:#274D5A ;
  font-family: Abel;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: white;
  width: 100%;
  margin-top: 75px;
  text-align: center;
  padding-top: 10px;
  height: 2.5rem;
}

.card-header {
  max-width: 60%;
  max-height: 80%;
}

.button {
  width: 105px;
  height: 128px;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  border: none;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: #FCD3AE;
  outline: none;
  margin-bottom: 35px;
  font-size: 20px;
  text-align: center;
  font-family: Abel;
}

/* CSS for Add Friend Modal */

.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0,0,0,0.3);
}

.modal-box {
  position: relative;
  top: 50%;
  left: 45%;
  transform: translate(-50%,-50%);
  min-height: 20%;
  width: 30%;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.25);
  z-index: 101;
  padding: 20px;
}

.modal-header {
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  font-family: Abel;
}

.modal-body {
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Abel;
}

