@import url('https://fonts.googleapis.com/css?family=Abel');

.welcomebody {
    width: 100%;
    height: 2880px;
    background: linear-gradient(180deg, #97D4D7 0%, #AEDDDE 19.27%, #F0F9FA 60.94%, #FFFFE0 82.29%, #FCD3AE 100%);
    font-family: Abel;
}

.header {
    padding: 42px 100%;
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    display: flex;
    line-height: 20px;
    z-index: 1000000
  }

.footer {
    background-color: #274D5A;
    position: fixed;
    font-size: 14px;
    font-family: Abel;
    color: white;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 45px;
    z-index: 1000000;
  }

/* Split the screen in half */
.split {
    height: 675px;
    width: 50%;
    z-index: 1;
    position: absolute;
    overflow-x: hidden;
    padding-top: 300px;
  }
  
  /* Control the left side */
  .left {
    left: 0;
  }
  
  /* Control the right side */
  .right {
    right: 0;
  }

  /* If you want the content centered horizontally and vertically */
  .centered {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    justify-content: center;
    align-items: center;
  }

.boxbutton {
    background-color: #274D5A;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  .button3 {
    outline: none;
    background-color: #ffffff; 
    color: #274D5A; 
    border: 2px solid #274D5A;
    border-radius: 4px;
    padding: 5px 18px;
    font-family: Abel;
    width: 150px;
    font-size: 16px;
    position: fixed;
    right: 380px;
    top: 24px;
  }
  
  .button3:hover {
    background-color: #407587;
    border: 2px solid #407587;
    color: white;
    cursor: pointer;
  }

  .button3:active {
    cursor: grabbing;
  }

  .button1 {
    outline: none;
    background-color: #274D5A; 
    color: white; 
    border: 2px solid #274D5A;
    border-radius: 4px;
    padding: 5px 18px;
    font-family: Abel;
    width: 150px;
    font-size: 16px;
    position: fixed;
    right: 200px;
    top: 24px;
  }
  
  .button1:hover {
    background-color: #407c91;
    border: 2px solid #407c91;
    color: white;
    cursor: pointer;
  }

  .button1:active {
    cursor: grabbing;
  }

  .button2 {
    outline: none;
    background-color: #274D5A; 
    color: white; 
    border: 2px solid #274D5A;
    border-radius: 4px;
    padding: 5px 18px;
    font-family: Abel;
    width: 150px;
    font-size: 16px;
    position: fixed;
    right: 20px;
    top: 24px;
  }
  
  .button2:hover {
    background-color: #407587;
    border: 2px solid #407587;
    color: white;
    cursor: pointer;
  }

  .button2:active {
    cursor: grabbing;
  }

#block1 {
  height: 720px;
}

#block2 {
  height: 720px;
}

#block3 {
  height: 720px;
}

#block4 {
  height: 720px;
}

.bigbutton {
  background-color: #ffffff; 
  color: #274D5A; 
  border: 2px solid #ffffff;
  outline: none;
  border-radius: 4px;
  padding: 20px 40px;
  font-family: Abel;
  width: 200px;
  font-size: 30px;
  box-shadow: 0 12px #407587;
}

.bigbutton:hover {
  background-color: #d4d4d4;
  border: 2px solid #d4d4d4;
  color: #274D5A;
  cursor: pointer;
  
}

.bigbutton:active {
  cursor: grabbing;
  box-shadow: 0 8px #274D5A;
  transform: translateY(20px);
}
